/**
 * @ComponentFor ForgotPasswordBlock
 */

import React from 'react';
import { styled } from '@glitz/react';
import connect from 'Shared/connect';
import ResetPasswordResultViewModel from 'ResetPassword/ResetPasswordResultViewModel.type';
import ForgotPasswordBlockType from './ForgotPasswordBlock.type';
import { connectWithFeedback, ConnectPropType } from 'Shared/Button/Feedback';
import Button, { Appearance as ButtonAppearance } from 'Shared/Button';
import EpiProperty from '@avensia/scope/EpiProperty';
import epiPropertyValue from '@avensia/scope/epi-property-value';
import { postJson, URLX, Breakpoint } from '@avensia/scope';
import { ACCOUNT_MODE, setActiveAccountMode, closeAccountPanel, updateEmail } from 'Account/action-creators';
import * as style from 'Shared/Style';
import Input from 'Shared/Fields/Text';
import { media } from '@glitz/core';

type StateType = {
  failedMessage: string;
};

type ConnectStateType = {
  activeAccountMode: ACCOUNT_MODE;
  email: string;
};

type DispatchPropType = {
  setActiveAccountMode: (mode: ACCOUNT_MODE) => void;
  closeAccountPanel: (snackMessage?: string) => void;
  updateEmail: (email: string) => void;
};

type PropType = ForgotPasswordBlockType & ConnectPropType & ConnectStateType & DispatchPropType;

const Form = styled.form({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: style.large,
  position: 'relative',
});

const InputLabel = styled.label({
  color: style.primary,
  fontSize: 21,
  fontWeight: 500,
  letterSpacing: -0.52,
  fontStyle: 'bold',
  marginTop: '15px',
});

const EmailField = styled(Input, {
  width: '100%',
  marginBottom: style.large,
});

const ErrorMessage = styled.div({
  color: '#FF0000',
  fontWeight: 'bold',
  display: 'flex',
  justifyContent: 'center',
  marginBottom: 20,
  textAlign: 'center',
});

const RightWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const BackToLoginButton = styled(Button, {
  marginBottom: '16px',
  margin: {
    top: '15px',
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    margin: {
      top: '15px',
    },
  }),
});

const Title = styled.h4({
  marginRight: '46px',
});

const ButtonWrapper = styled.div({
  display: 'flex',
  margin: {
    top: '10px',
  },
});

class ForgotPassword extends React.Component<PropType, StateType> {
  constructor(props: PropType) {
    super(props);
    this.state = {
      failedMessage: null,
    };
  }

  onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const email = this.props.email.trim();
    if (!email) {
      return;
    }

    this.setState({ failedMessage: null });

    const url = new URLX('/ForgotPasswordBlock/SendResetPasswordLink');
    url.searchParams.set('email', email);

    this.props.feedback.push(
      postJson(url)
        .then((result: ResetPasswordResultViewModel) => {
          if (result.success) {
            return Promise.resolve();
          } else {
            return Promise.reject(result.message);
          }
        })
        .catch(error => {
          this.setFailMessage(error);
          return Promise.reject(null);
        })
        .then(() => this.props.closeAccountPanel(epiPropertyValue(this.props.successMessage))),
    );
  };

  setFailMessage(failedMessage: string) {
    this.setState({ failedMessage });
  }

  onEmailFieldInput = (e: React.FormEvent<HTMLInputElement>) => {
    const value: string = (e.target as HTMLInputElement).value;
    this.props.updateEmail(value);
    this.setState({
      failedMessage: '',
    });
  };

  onBackClick = () => {
    this.props.setActiveAccountMode(ACCOUNT_MODE.Login);
  };

  render() {
    const FeedbackButton = this.props.feedback.Button;
    return (
      <Form onSubmit={this.onSubmit}>
        <EpiProperty for={this.props.blockTitle} component={Title} />
        <EpiProperty for={this.props.mainContent} component="div" />
        <InputLabel>{epiPropertyValue(this.props.emailLabel)}</InputLabel>
        <EmailField
          required
          type="email"
          value={this.props.email}
          defaultValue={this.props.email}
          placeholder={epiPropertyValue(this.props.emailLabel)}
          onChange={this.onEmailFieldInput}
          css={
            this.state.failedMessage && {
              border: {
                xy: {
                  color: '#FF0000',
                  style: 'solid',
                },
              },
            }
          }
        />
        {!!this.state.failedMessage && <ErrorMessage>{this.state.failedMessage}</ErrorMessage>}
        <RightWrapper>
          <React.Fragment>
            <ButtonWrapper>
              <FeedbackButton type="submit" appearance={ButtonAppearance.Primary}>
                <EpiProperty for={this.props.submitButtonCaption} />
              </FeedbackButton>
            </ButtonWrapper>
            <BackToLoginButton
              onClick={this.onBackClick}
              appearance={ButtonAppearance.Link}
              style={{ marginTop: '15px' }}
              variant={false}
            >
              <EpiProperty for={this.props.backToLoginLabel} />
            </BackToLoginButton>
          </React.Fragment>
        </RightWrapper>
      </Form>
    );
  }
}

const ForgotPasswordWithFeedback = connectWithFeedback()(ForgotPassword);

export default connect(
  (state): ConnectStateType => ({
    activeAccountMode: state.currentUser.activeAccountMode,
    email: state.currentUser.accountPanelEmail,
  }),
  (dispatch): DispatchPropType => ({
    setActiveAccountMode(mode) {
      return dispatch(setActiveAccountMode(mode));
    },
    closeAccountPanel(snackMessage: string) {
      return dispatch(closeAccountPanel(snackMessage));
    },
    updateEmail(email: string) {
      return dispatch(updateEmail(email));
    },
  }),
)(ForgotPasswordWithFeedback);
