/**
 * @ComponentFor LoginBlock
 */
import React from 'react';
import { styled } from '@glitz/react';
import connect from 'Shared/connect';
import {
  URLX,
  translate,
  EpiProperty,
  epiPropertyValue,
  pushState,
  replaceState,
  postJson,
  addUserLog,
  Breakpoint,
} from '@avensia/scope';
import LoginModelType from './LoginModel.type';
import LoginBlockType from './LoginBlock.type';
import { Checkbox } from 'Shared/Fields/Toggle';
import Input from 'Shared/Fields/Text';
import LoginResultViewModelType from './LoginResultViewModel.type';
import { connectWithFeedback, ConnectPropType } from 'Shared/Button/Feedback';
import { ACCOUNT_MODE, setActiveAccountMode, closeAccountPanel, updateEmail } from 'Account/action-creators';
import * as style from 'Shared/Style';
import Viewport from 'Shared/Viewport';
import RegisterValidationResult from 'Account/Register/RegisterValidationResult.type';
import Button, { Appearance as ButtonAppearance } from 'Shared/Button';
import { media } from '@glitz/core';

type StateType = {
  password: string;
  rememberMe: boolean;
  showPassword: boolean;
  errorMessage: string;
  informationMessage: string;
  url: string;
};

type ConnectStateType = {
  activeAccountMode: ACCOUNT_MODE;
  accountPanelIsOpen: boolean;
  hardReloadToAfterLogin: URLX;
  panelMessage: string;
  email: string;
};

type DispatchPropType = {
  setActiveAccountMode: (mode: ACCOUNT_MODE) => void;
  closeAccountPanel: (snackMessage?: string) => void;
  updateEmail: (email: string) => void;
};

type PropType = LoginBlockType &
  ConnectPropType &
  ConnectStateType &
  DispatchPropType & { email: string; updateEmail: (email: string) => void };

class Login extends React.Component<PropType, Partial<StateType>> {
  constructor(props: PropType) {
    super(props);

    this.state = {
      password: '',
      rememberMe: false,
      showPassword: false,
      errorMessage: null,
      informationMessage: null,
      url: null,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    const url = new URL(window.location.href);
    if (url.searchParams.get('confirm-message')) {
      this.setInformationMessage(translate('/Account/Login/EmailConfirmed'));
    }
  }

  handleInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
    const value: string = (e.target as HTMLInputElement).value;
    if (e.currentTarget.name === 'email') {
      this.props.updateEmail(value);
    } else {
      this.setState({
        [e.currentTarget.name]: value,
        errorMessage: null,
      });
    }
  };

  handleToggleChange = (e: React.FormEvent<HTMLInputElement>): void => {
    this.setState({ [e.currentTarget.name]: (e.target as HTMLInputElement).checked });
  };

  onForgotPasswordClick = (): void => {
    this.props.setActiveAccountMode(ACCOUNT_MODE.ForgotPassword);
  };

  onRegisterClick = (): void => {
    this.props.setActiveAccountMode(ACCOUNT_MODE.Registration);
  };

  closeErrorMessage = (): void => {
    this.setState({ errorMessage: null });
  };

  toggleShowPassword = (): void => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  setErrorMessage(errorMessage: string) {
    this.setState({ errorMessage });
  }

  setErrorUrl(url: string) {
    this.setState({ url });
  }

  setInformationMessage(informationMessage: string) {
    this.setState({ informationMessage });
  }

  requestNewConfirmationMail = (): void => {
    postJson(`${this.state.url}`, { email: this.props.email })
      .then((response: RegisterValidationResult) => {
        if (response.isValid) {
          this.setInformationMessage(response.message);
          this.setErrorUrl(null);
        } else {
          return Promise.reject(response);
        }
      })
      .catch(() => {
        this.setErrorMessage(translate('/Account/Login/ServerError'));
        this.setErrorUrl(null);
      });
  };

  handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    this.closeErrorMessage();
    this.setErrorUrl(null);
    this.setInformationMessage(null);

    const email = this.props.email.trim();
    const password = this.state.password.trim();
    const rememberMe = this.state.rememberMe;
    const query: LoginModelType = { email, password, rememberMe };
    const pageToRedirectTo = epiPropertyValue(this.props.pageToRedirectTo)
      ? new URLX(epiPropertyValue(this.props.pageToRedirectTo))
      : null;

    this.props.feedback.push(
      postJson('/loginblock', query)
        .then((result: LoginResultViewModelType) => {
          if (result.success) {
            addUserLog('Login successful');
            return Promise.resolve();
          } else {
            addUserLog('Login failed');
            return Promise.reject(result);
          }
        })
        .then(() => {
          const url = new URL(window.location.href);
          if (url.searchParams.get('ReturnUrl')) {
            window.location.href = `${url.origin}/${url.searchParams.get('ReturnUrl')}`;
            return;
          }

          if (this.props.hardReloadToAfterLogin) {
            window.location.href = this.props.hardReloadToAfterLogin.toString();
            return;
          }

          if (pageToRedirectTo) {
            return pushState(pageToRedirectTo, { includeAppShellData: true }).then(() => {
              if (this.props.accountPanelIsOpen) {
                this.props.closeAccountPanel(translate('/Account/Login/Successful'));
              }
            });
          } else {
            return replaceState(null, { includeAppShellData: true }).then(() => {
              this.props.closeAccountPanel(translate('/Account/Login/Successful'));
            });
          }
        })
        .catch(errorMessage => {
          this.setErrorMessage(errorMessage.message || null);
          this.setErrorUrl(errorMessage.url || null);
          this.setInformationMessage(errorMessage.informationText || null);
          return Promise.reject(null);
        }),
    );
  };

  render() {
    const FeedbackButton = this.props.feedback.Button;
    return (
      <Viewport>
        {(isCompact: boolean) => (
          <SigninForm onSubmit={this.handleSubmit}>
            <EpiProperty for={this.props.blockTitle} component={H4} />
            <InputLabel>{epiPropertyValue(this.props.emailLabel)}</InputLabel>
            <EmailField
              required
              type="email"
              name="email"
              value={this.props.email}
              placeholder={epiPropertyValue(this.props.emailPlaceholder)}
              onChange={this.handleInputChange}
            />
            <InputLabel>{epiPropertyValue(this.props.passwordLabel)}</InputLabel>
            <PasswordField
              required
              type={this.state.showPassword ? 'text' : 'password'}
              name="password"
              value={this.state.password}
              placeholder={epiPropertyValue(this.props.passwordPlaceholder)}
              onChange={this.handleInputChange}
              css={
                this.state.errorMessage && {
                  border: {
                    xy: {
                      color: '#FF0000',
                      style: 'solid',
                    },
                  },
                }
              }
            />
            {this.state.errorMessage && (
              <ErrorMessage>
                <styled.Span>{this.state.errorMessage}</styled.Span>
              </ErrorMessage>
            )}

            {this.props.panelMessage && (
              <ErrorMessage>
                <styled.Span>{this.props.panelMessage}</styled.Span>
              </ErrorMessage>
            )}

            {this.state.informationMessage && (
              <InformationMessage>
                <styled.Span>
                  {this.state.informationMessage}
                  {this.state.url && (
                    <styled.P
                      onClick={this.requestNewConfirmationMail}
                      style={{
                        color: style.BLACK,
                        cursor: 'pointer',
                        paddingLeft: '3px',
                        fontSize: '0.875rem',
                        fontWeight: 700,
                      }}
                    >
                      {translate('/Account/Login/RequestEmailConfirmationMail')}
                    </styled.P>
                  )}
                </styled.Span>
              </InformationMessage>
            )}

            <CenterWrapper2>
              <ShowPasswordWrapper>
                <ShowPassword
                  type="checkbox"
                  name="showPassword"
                  checked={this.state.showPassword}
                  onChange={this.handleToggleChange}
                />
                <CheckboxLabel>
                  <EpiProperty for={this.props.showPasswordLabel} />
                </CheckboxLabel>
              </ShowPasswordWrapper>

              <RememberMeWrapper>
                <RememberMe name="rememberMe" checked={this.state.rememberMe} onChange={this.handleToggleChange} />
                <CheckboxLabel>
                  <EpiProperty for={this.props.rememberMeLabel} />
                </CheckboxLabel>
              </RememberMeWrapper>
            </CenterWrapper2>

            <CenterWrapper>
              {isCompact ? (
                <ButtonWrapper style={{ marginTop: 35, marginBottom: 23 }}>
                  <FeedbackButton type="submit" appearance={ButtonAppearance.Primary}>
                    <EpiProperty for={this.props.submitButtonCaption} />
                  </FeedbackButton>
                </ButtonWrapper>
              ) : (
                <ButtonWrapper style={{ marginTop: 18, marginBottom: 23 }}>
                  <FeedbackButton type="submit" appearance={ButtonAppearance.Primary}>
                    <EpiProperty for={this.props.submitButtonCaption} />
                  </FeedbackButton>
                </ButtonWrapper>
              )}
              {isCompact ? (
                <CenterWrapper>
                  <ForgotPasswordButton onClick={this.onForgotPasswordClick} appearance={ButtonAppearance.Link}>
                    <EpiProperty for={this.props.forgotPasswordLabel} />
                  </ForgotPasswordButton>
                  <RegisterUserButton onClick={this.onRegisterClick} appearance={ButtonAppearance.Link}>
                    <EpiProperty for={this.props.registerLabel} />
                  </RegisterUserButton>
                </CenterWrapper>
              ) : (
                <CenterWrapper2>
                  <ForgotPasswordButton
                    style={{ marginRight: 14 }}
                    onClick={this.onForgotPasswordClick}
                    appearance={ButtonAppearance.Link}
                  >
                    <EpiProperty for={this.props.forgotPasswordLabel} />
                  </ForgotPasswordButton>
                  <RegisterUserButton
                    style={{ marginLeft: 14 }}
                    onClick={this.onRegisterClick}
                    appearance={ButtonAppearance.Link}
                  >
                    <EpiProperty for={this.props.registerLabel} />
                  </RegisterUserButton>
                </CenterWrapper2>
              )}
            </CenterWrapper>
          </SigninForm>
        )}
      </Viewport>
    );
  }
}
const LoginWithFeedback = connectWithFeedback()(Login);

export default connect(
  (state): ConnectStateType => ({
    activeAccountMode: state.currentUser.activeAccountMode,
    accountPanelIsOpen: state.currentUser.accountPanelIsOpen,
    hardReloadToAfterLogin: state.currentUser.hardReloadToAfterLogin,
    email: state.currentUser.accountPanelEmail,
    panelMessage: state.currentUser.panelMessage,
  }),
  (dispatch): DispatchPropType => ({
    setActiveAccountMode(mode) {
      return dispatch(setActiveAccountMode(mode));
    },
    closeAccountPanel(snackMessage?: string) {
      return dispatch(closeAccountPanel(snackMessage));
    },
    updateEmail(email: string) {
      return dispatch(updateEmail(email));
    },
  }),
)(LoginWithFeedback);

const ButtonWrapper = styled.div({
  display: 'flex',
});

const ErrorMessage = styled.div({
  color: '#FF0000',
  fontWeight: 'bold',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  marginBottom: 20,
});

const InformationMessage = styled.div({
  color: style.BLACK,
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  marginBottom: 20,
});

const H4 = styled.h4({});

const InputLabel = styled.label({
  color: style.primary,
  fontSize: 21,
  fontWeight: 500,
  letterSpacing: -0.52,
});

const ShowPasswordWrapper = styled.div({
  marginRight: 15,
  display: 'inline-flex',
});

const RememberMeWrapper = styled.div({
  marginLeft: 15,
  display: 'inline-flex',
});

const CheckboxLabel = styled.label({
  fontSize: 16,
  fontWeight: 300,
  marginLeft: 9,
  marginBottom: 5,
  color: style.secondaryDarker,
});

const SigninForm = styled.form({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: style.large,
  position: 'relative',
});

const EmailField = styled(Input, {
  width: '100%',
  marginBottom: style.large,
});

const PasswordField = styled(Input, {
  width: '100%',
  marginBottom: style.large,
});

const ShowPassword = styled(Checkbox, {});

const RememberMe = styled(Checkbox, {});

const CenterWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const CenterWrapper2 = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
});

const ForgotPasswordButton = styled(Button, {});

const RegisterUserButton = styled(Button, {
  margin: {
    bottom: '16px',
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    margin: {
      bottom: 0,
    },
  }),
});
