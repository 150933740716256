/**
 * @ComponentFor RegisterBlock
 */

import React from 'react';
import { styled } from '@glitz/react';
import connect from 'Shared/connect';
import {
  translate,
  EpiProperty,
  epiPropertyValue,
  postJson,
  addUserLog,
  updateAppShellData,
  Breakpoint,
  Link,
} from '@avensia/scope';
import RegisterValidationResultType from './RegisterValidationResult.type';
import { connectWithFeedback, ConnectPropType } from 'Shared/Button/Feedback';
import Button, { Appearance as ButtonAppearance } from 'Shared/Button';
import { Checkbox } from 'Shared/Fields/Toggle';
import RegisterPropType from './Registerblock.type';
import Input from 'Shared/Fields/Text';
import { ACCOUNT_MODE, setActiveAccountMode, closeAccountPanel } from 'Account/action-creators';
import * as style from 'Shared/Style';
import { media } from '@glitz/core';

type StateType = {
  email: string;
  password: string;
  rememberMe: boolean;
  showPassword: boolean;
  acceptTerms: boolean;
  errorMessage: string;
  activePanel: ACCOUNT_MODE;
};

type ConnectStateType = {
  activeAccountMode: ACCOUNT_MODE;
};

type DispatchPropType = {
  updateAppShellData: () => Promise<void>;
  setActiveAccountMode: (mode: ACCOUNT_MODE) => void;
  closeAccountPanel: (snackMessage?: string) => void;
};

type PropType = RegisterPropType & ConnectPropType & ConnectStateType & DispatchPropType;

const H4 = styled.h4({});

const ErrorMessage = styled.div({
  color: style.RED,
  fontWeight: 'bold',
  display: 'flex',
  justifyContent: 'center',
  margin: {
    bottom: '20px',
  },
  textAlign: 'center',
});

const SigninForm = styled.form({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: style.large,
  position: 'relative',
});

const EmailField = styled(Input, {
  width: '100%',
  marginBottom: style.large,
});

const PasswordField = styled(Input, {
  width: '100%',
  marginBottom: style.large,
});

const FormBottom = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  width: '100%',
});

const BackToLoginButton = styled(Button, {
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    marginRight: '14px',
  }),
});

const CheckboxWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  textAlign: 'center',
  color: style.secondaryDarker,
});

const CheckboxLabel = styled.label({
  margin: {
    top: '4px',
    left: '9px',
  },
  font: {
    size: '16px',
    weight: 300,
  },
});

const InputLabel = styled.label({
  color: style.primary,
  font: {
    size: '21px',
    weight: 500,
  },
  letterSpacing: -0.52,
});

const ForgotPasswordButton = styled(Button, {
  margin: {
    bottom: '21px',
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    margin: {
      left: '14px',
      bottom: 0,
    },
  }),
});

const LinkWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: {
    top: '17px',
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    margin: {
      top: '21px',
    },
  }),
});

const ButtonWrapper = styled.div({
  display: 'flex',
});

class Register extends React.Component<PropType, Partial<StateType>> {
  constructor(props: PropType) {
    super(props);

    this.state = {
      email: '',
      password: '',
      rememberMe: false,
      showPassword: false,
      acceptTerms: false,
      errorMessage: null,
      activePanel: ACCOUNT_MODE.Registration,
    };
  }

  handleInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
    const value: string = (e.target as HTMLInputElement).value;

    this.setState({
      [e.currentTarget.name]: value,
      errorMessage: null,
    });
  };

  handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (this.state.acceptTerms) {
      this.closeErrorMessage();

      const email = this.state.email.trim();
      const password = this.state.password.trim();
      const query = { email, password };

      this.props.feedback.push(
        postJson('/register', query)
          .then(
            (result: RegisterValidationResultType) => {
              if (result.isValid) {
                addUserLog('Register successful');
                this.props.closeAccountPanel(translate('/Account/Registration/RegistrationSuccessful'));
                return Promise.resolve();
              } else {
                addUserLog('Login failed');
                this.setState({ errorMessage: result.message });
                return Promise.reject(null);
              }
            },
            () => {
              this.setState({ errorMessage: translate('/Errors/Unknown') });
              return Promise.reject(null);
            },
          )
          .then(() => this.props.updateAppShellData()),
      );
    } else {
      this.setState({ errorMessage: translate('/Errors/Terms') });
    }
  };

  closeErrorMessage = (): void => {
    this.setState({ errorMessage: null });
  };

  toggleShowPassword = (): void => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  onGoToLoginClick = () => {
    this.props.setActiveAccountMode(ACCOUNT_MODE.Login);
  };

  onForgotPasswordClick = (): void => {
    this.props.setActiveAccountMode(ACCOUNT_MODE.ForgotPassword);
  };

  render() {
    const FeedbackButton = this.props.feedback.Button;
    const {
      blockTitle,
      emailLabel,
      passwordLabel,
      showPasswordLabel,
      termsAgreementLink,
      termsAgreementLinkText,
      registerButtonCaption,
      goToLoginLabel,
      forgotPasswordLabel,
    } = this.props;
    return (
      <SigninForm onSubmit={this.handleSubmit}>
        <EpiProperty for={blockTitle} component={H4} />
        <InputLabel>{epiPropertyValue(emailLabel)}</InputLabel>
        <EmailField
          required
          type="email"
          name="email"
          value={this.state.email}
          placeholder={epiPropertyValue(emailLabel)}
          onChange={this.handleInputChange}
          css={
            this.state.errorMessage && {
              border: {
                xy: {
                  color: style.RED,
                  style: 'solid',
                },
              },
            }
          }
        />
        <InputLabel>
          {epiPropertyValue(passwordLabel)} <em>(minimum 6 characters)</em>
        </InputLabel>
        <PasswordField
          required
          type={this.state.showPassword ? 'text' : 'password'}
          name="password"
          value={this.state.password}
          placeholder={epiPropertyValue(passwordLabel)}
          onChange={this.handleInputChange}
          css={
            this.state.errorMessage && {
              border: {
                xy: {
                  color: style.RED,
                  style: 'solid',
                },
              },
            }
          }
        />
        {this.state.errorMessage && <ErrorMessage>{this.state.errorMessage}</ErrorMessage>}
        <CheckboxWrapper>
          <Checkbox
            type="checkbox"
            checked={this.state.showPassword}
            onChange={e => this.setState({ showPassword: (e.target as HTMLInputElement).checked })}
          />
          <CheckboxLabel>
            <EpiProperty for={showPasswordLabel} />
          </CheckboxLabel>
        </CheckboxWrapper>
        <CheckboxWrapper>
          <Checkbox
            type="checkbox"
            checked={this.state.acceptTerms}
            onChange={e => this.setState({ acceptTerms: (e.target as HTMLInputElement).checked })}
            style={{ paddingBottom: '28px' }}
          />
          <CheckboxLabel style={{ paddingBottom: '12px' }} className="termLabel">
            <Link to={epiPropertyValue(termsAgreementLink)} target="_blank">
              <EpiProperty for={termsAgreementLinkText} />
            </Link>
          </CheckboxLabel>
        </CheckboxWrapper>
        <FormBottom>
          <ButtonWrapper className="hexagonbutton">
            <FeedbackButton type="submit" appearance={ButtonAppearance.Primary}>
              <EpiProperty for={registerButtonCaption} />
            </FeedbackButton>
          </ButtonWrapper>
          <LinkWrapper>
            <BackToLoginButton onClick={this.onGoToLoginClick} appearance={ButtonAppearance.Link} variant={false}>
              <EpiProperty for={goToLoginLabel} />
            </BackToLoginButton>
            <ForgotPasswordButton onClick={this.onForgotPasswordClick} appearance={ButtonAppearance.Link}>
              <EpiProperty for={forgotPasswordLabel} />
            </ForgotPasswordButton>
          </LinkWrapper>
        </FormBottom>
      </SigninForm>
    );
  }
}

const RegisterWithFeedback = connectWithFeedback()(Register);

export default connect(
  (state): ConnectStateType => ({
    activeAccountMode: state.currentUser.activeAccountMode,
  }),
  (dispatch): DispatchPropType => ({
    updateAppShellData() {
      return dispatch(updateAppShellData());
    },
    setActiveAccountMode(mode) {
      return dispatch(setActiveAccountMode(mode));
    },
    closeAccountPanel(snackMessage?: string) {
      return dispatch(closeAccountPanel(snackMessage));
    },
  }),
)(RegisterWithFeedback);
